<template>
  <div class="d-flex flex-column flex-root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-bind:logo="require('@/assets/images/logo.png')"></Loader>
    <KTHeader></KTHeader>

    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper padding-0">

      <!--begin::Content-->

      <div
        class="flex-row-fluid d-flex flex-column position-relative overflow-hidden landing bg-color1"
      >
        <v-row no-gutters>
            <v-col cols="12">
                <div :style="{ backgroundImage: `url(${backgroundImage})`, height: '530px'}" style="background-size: cover; background-position: center">
                  <div class="landing-bg">
                    <div  class="container landing-contents">
                      <div class="userinfo d-flex">
                        <div class="d-flex coin">
                          <img :src="require('@/assets/images/coin.png')" height="40">
                          <div class="text">
                            
                            <p>This is fastmind coin</p>
                            <p class="text-coin">You have<strong> 7 coins</strong></p>
                          </div>
                        </div>
                        <div class="helper d-flex">
                          <span class="fa fa-question-circle"></span>
                        </div>
                        <div class="profile-img">
                          <img v-for="item in currentUser.currentAvatar" class="avatar-image" :src="item.file" :key="item._id" :style="`z-index: ${item.zindex-900}`">
                        </div>
                      </div>
                      <v-btn rounded  class="text-capitalize backbutton"><span class="material-icons">arrow_back_ios</span>Regresar al menú</v-btn>
                      <div class="text-center">
                          <h1 class="landing-title">Misterios del Arte y la ciencia</h1>
                      </div>
                    </div>
                  </div>
                  
                </div>
            </v-col>
        </v-row>
        <div class="container my-20 ">
          <v-row no-gutters>
            
            <v-col cols="12" md="4" v-for="item in homeData" v-bind:key="item._id">
              <b-card
                :title="item.title"
                :img-src="item.thumb"
                img-alt="Image"
                img-top
                tag="article"
                class="mb-8 mx-4 video-card"

              >
                <b-card-text>
                  {{item.description}}
                </b-card-text>

                <router-link :to="{name: 'video', params: {id: item._id}}">
                  <b-button variant="primary" class="float-right action-button">
                    <i class="fas fa-paint-brush mr-1"></i>
                    ver detalles
                  </b-button>
                </router-link>
                
              </b-card>
            </v-col>

          </v-row>
        </div>
      </div>
      <!--end::Content-->
    </div>

    <KTFooter></KTFooter>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/landing/landing.scss";

</style>

<script>
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import Loader from "@/view/content/Loader.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import {
  GET_HOME_DATA,
  START_TIMER,
} from "@/core/services/store/data.module.js";

import { mapGetters } from "vuex";


export default {
  name: "landing",
  components: {
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    Loader
  },
  data() {
      return {
      }
  },
  created() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    this.$store.dispatch(START_TIMER);

    this.$store
          .dispatch(GET_HOME_DATA)
          .then(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
  },
  methods: {

  },
  computed: {
    ...mapGetters([
        "homeData",
        "currentUser",
    ]),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg.jpg";
    }
  }
};
</script>
